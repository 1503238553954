@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

[type='checkbox']:checked, [type='radio']:checked,
[type='checkbox']:active, [type='radio']:active,
[type='checkbox']:focus, [type='radio']:focus,
[type='checkbox']:hover, [type='radio']:hover {
    background-color: #000;
    opacity: 1;
}

[type='checkbox']:checked, [type='radio']:checked,
[type='checkbox']:active, [type='radio']:active,
[type='checkbox']:focus, [type='radio']:focus {
    opacity: 0.5 !important;
}

[data-filepond-item-state=processing-complete] .filepond--item-panel {
    background-color: #a4e704 !important;
}

.max-w-90 {
    width: 90%;
}

body.min-w-400 {
    min-width: 400px;
}

.w-100 {
    width: 100px;
}

.w-125 {
    width: 125px;
}

#toggle {
    background-color: transparent !important;
    border: none !important;
}

#toggle:focus {
    outline: none;
}

.toggle-container.disabled {
    opacity: 0.65;
}

#toggle:checked {
    background-image: none !important;
    background-color: transparent !important;
}

#portal-sidebar {
    min-height: calc(100vh - 64px);
}
